const TimeLine = [
  { text: '9:00 am', value: '1' },
  { text: '9:30 am', value: '1' },
  { text: '10:00 am', value: '1' },
  { text: '10:30 am', value: '1' },
  { text: '11:00 am', value: '1' },
  { text: '11:30 am', value: '1' },
  { text: '12:00 pm', value: '1' },
  { text: '12:30 pm', value: '1' },
  { text: '1:00 pm', value: '1' },
  { text: '1:30 pm', value: '1' },
  { text: '2:00 pm', value: '1' },
  { text: '2:30 pm', value: '1' },
  { text: '3:00 pm', value: '1' },
  { text: '3:30 pm', value: '1' },
  { text: '4:00 pm', value: '1' },
  { text: '4:30 pm', value: '1' },
  { text: '5:00 pm', value: '1' },
  { text: '5:30 pm', value: '1' },
  { text: '6:00 pm', value: '1' },
  { text: '6:30 pm', value: '1' },
  { text: '7:00 pm', value: '1' },
  { text: '7:30 pm', value: '1' },
  { text: '8:00 pm', value: '1' },
  { text: '8:30 pm', value: '1' },
  { text: '9:00 pm', value: '1' },
]

const bookContent = [
  { text: '30 Min Cage', value: 5, duration: 1 },
  { text: '60 Min Cage', value: 6, duration:2 },
  { text: '90 Min Cage', value: 29, duration: 3 },
  { text: '120 Min Cage', value: 30, duration: 4 },
  { text: '30 Min Outdoor Cage', value: 51, duration: 1 },
  { text: '60 Min Outdoor Cage', value: 52, duration: 2 },
  { text: '90 Min Outdoor Cage', value: 53, duration: 3 },
  { text: '120 Min Outdoor Cage', value: 54, duration: 4 },

  { text: 'Baseball/Batting 60 Min', value: 87, duration: 2 },
  { text: 'Baseball/Batting 30 Min', value: 83, duration: 1 },
  { text: 'Softball/Batting 30 Min', value: 84, duration: 1 },
  { text: 'Softball/Batting 60 Min', value: 88, duration: 2 },
  { text: 'Baseball/Pitching 30 Min', value: 85, duration: 1 },
  { text: 'Baseball/Pitching 60 Min', value: 89, duration: 2 },
  { text: 'Softball/Pitching 30 Min', value: 86, duration: 1 },
  { text: 'Softball/Pitching 60 Min', value: 90, duration: 2 }
]

export {
  TimeLine,
  bookContent,
}