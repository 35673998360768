export const GET_RESOURCE = 'GET_RESOURCE'
export const GET_APPOINTMENT = 'GET_APPOINTMENT'
export const GET_CLASS = 'GET_CLASS'
export const GET_CLIENTS = 'GET_CLIENTS'
export const GET_CLASS_VISITS = 'GET_CLASS_VISITS'
export const GET_CLASS_DETAIL = 'GET_CLASS_DETAIL'
export const GET_BOOK = 'GET_BOOK'
export const GET_APPOINTMENT_BY_WEEK = 'GET_APPOINTMENT_BY_WEEK'
export const SELECT_RESOURCE = 'SELECT_RESOURCE'

export const SET_STARTDATE = 'SET_STARTDATE'
export const SET_VIEWMODE = 'SET_VIEWMODE'
